import Vue from 'vue'
import Vuex from 'vuex'
import {apiPost} from '@/utils/api.js'
import {apiGet} from "../utils/api";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: null,
        notes: [],
        launcher: [],
        windowInnerSize: {height: window.innerHeight, width: window.innerWidth},
        token: localStorage.getItem('token'),
        contextMenuData: null,
    },
    mutations: {
        login(state, user) {
            state.user = user
        },
        logout(state) {
            state.user = false
        },
        setNotes(state, notes) {
            state.notes = notes
        },
        setLauncher(state, notes) {
            state.launcher = notes
        },
        setWindowInnerSize(state, val) {
            state.windowInnerSize = val

            console.log(["setWindowInnerHeight", val])
        },
        setToken(state, token) {
            localStorage.setItem('token', token)

            state.token = token
        },
        setContextMenuData(state, val) {
            state.contextMenuData = val
        },
    },
    actions: {
        // get user info
        getUser({commit}) {
            return new Promise((resolve, reject) => {
                apiPost('/auth/info').then(response => {
                    if (response.code === 0) {
                        commit('login', response.data)
                        resolve(response)
                    } else {
                        reject()
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        loadNotes({commit}) {
            apiGet('/note/all').then(result => {
                console.log(result)
                if (result.code === 0) {
                    commit('setNotes', result.data || [])
                    commit('setLauncher', []) // @task
                }
            })
        },

        setContextMenu({commit}) {
            apiGet('/note/all').then(result => {
                console.log(result)
                if (result.code === 0) {
                    commit('se', result.data || [])
                    commit('setLauncher', []) // @task
                }
            })
        },

    }
})

export default store
