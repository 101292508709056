import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)
Vue.use(ElementUI);

Vue.config.productionTip = false

// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()

    // if ((['登录', '注册', '忘记密码'].indexOf(to.name) < 0) && !store.state.user) {
    //     store.dispatch('getUser').then(() => {
    //         next()
    //     }).catch(() => {
    //         next({name: '登录'})
    //     })
    // } else {
    //     next()
    // }
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
}).$mount('#app')
