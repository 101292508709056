import request from '@/utils/request'

export function apiPost(action, data, openLoading) {
    return request({
        url: action,
        method: 'post',
        data,
        openLoading: openLoading
    })
}

export function apiPut(action, data, openLoading) {
    return request({
        url: action,
        method: 'put',
        data,
        openLoading: openLoading
    })
}

export function apiGet(action, data, openLoading) {
    return request({
        url: action,
        method: 'get',
        data,
        openLoading: openLoading
    })
}

export function apiDelete(action, data, openLoading) {
    return request({
        url: action,
        method: 'delete',
        data,
        openLoading: openLoading
    })
}

export function apiExport(action, data) {
    if (data) data = JSON.parse(JSON.stringify(data)); else data = {}
    data.export = 1

    return request({
        url: action,
        method: 'post',
        responseType: 'blob',
        data
    })
}
