import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const asyncRouterMap = [
    {
        path: '/',
        name: '书签',
        component: () => import('@/views/home'),
        meta: {title: '书签'},
    },

    // auth
    {
        path: '/login',
        name: '登录',
        component: () => import('@/views/auth/login'),
        meta: {title: '登录'},
    },
    {
        path: '/resetPassword',
        name: '忘记密码',
        component: () => import('@/views/auth/resetPassword'),
        meta: {title: '忘记密码'},
    },
    {
        path: '/register',
        name: '注册',
        component: () => import('@/views/auth/register'),
        meta: {title: '注册'},
    },
    {
        path: '/bookmarks',
        name: '书签',
        component: () => import('@/views/bookmarks'),
        meta: {title: '书签'},
    },
    {
        path: '/add/url',
        name: '收藏网址',
        component: () => import('@/views/add/url'),
        meta: {title: '收藏网址'},
    },
]

export default new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: asyncRouterMap
})
