<template>
    <div class="body" id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style>
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c2c2c2;
        background-clip: padding-box;
        min-height: 28px;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a0
    }
</style>
