import axios from 'axios'
import store from '../store'
import {Message} from "element-ui";

let isOpenLoading = false;

// create an axios instance
const service = axios.create({
    baseURL: '',
    timeout: 30000
});

// request interceptor
service.interceptors.request.use(
    config => {
        if (config.openLoading) {
            isOpenLoading = true;
            // Indicator.open()
        }

        // 登录Token
        config.headers['Token'] = store.state.token;

        return config
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error)
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        if (response.headers['token'] || response.headers['Token']) {
            const token = response.headers['token'] || response.headers['Token']

            console.log(['setToken', token])
            store.commit('setToken', token)
        }

        // 下载文件的保存
        if (response.headers['content-disposition']) {
            if (response.headers['content-disposition'].indexOf('attachment;filename=') >= 0) {
                let fileName = response.headers['content-disposition'].replace('attachment;filename=', '');
                const blob = new Blob([response.data], {type: 'application/vnd.ms-excel;charset=utf-8'});

                if ('download' in document.createElement('a')) { // 非IE下载
                    const elink = document.createElement('a');
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink)
                } else { // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
            }

            return {code: 200}
        }

        // 下载失败时的错误显示
        if (response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function () {
                const result = JSON.parse(this.result);

                if (result.code !== 0) {
                    Message.error(result.msg || 'Error');
                }
            };
            reader.readAsText(response.data, 'UTF-8');

            return {code: 400}
        }

        if (isOpenLoading) {
            isOpenLoading = false;
            //Indicator.close()
        }
        const res = response.data;

        res.code = parseInt(res.code);

        console.log(['res', res])
        // if the custom code is not 20000, it is judged as an error.

        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        if (res.code === 401) {
            store.commit('logout');
            let err = new Error(res.msg || 'Error')
            err.code = 401
            Message.info(res.msg || 'Error');
            return Promise.reject(err)
        } else if (res.code !== 0) {
            Message.error(res.msg || 'Error');

            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error); // for debug
        Message.error(error.message || 'Error');
        return Promise.reject(error)
    }
);

export default service
